export default {
	permissionManage: "Permission Management",
	matchLeads: "Participate in Lead Matching",
	closeReason: "Closure Reason",
	leadsPreferences: "Lead Preferences",
	systemAdmin: "System Administrator",
	sales: "Sales",
	companyAccount: "Company Account",
	name: "Name",
	phone: "Phone",
	email: "Email",
	position: "Position",
	permission: "Permission",
	assignPermission: "Assign Permission",
	account: "Account",
	createAccount: "Create Account",
	userName: "User Name",
	company: "Company",
	toBeReceived: "To be Received",
	totalLeadsReceived: "Leads Received",
	totalInProgress: "In Progress",
	totalLeadsToDeal: "Leads Won",
	totalLeadsFailed: "Leads Lost",

	tooltips: {
		permission1: "Enabling lead matching means the installer participates in platform lead matching",
		permission2: "When enabled, the system administrator is by default the agent account registered by the installer",

		companyAccount2:
			"Sales refers to whether the account has the rights to receive leads assigned by the administrator and view related data",

		systemAdmin1: "Enabling lead matching means the installer participates in platform lead matching",
		systemAdmin2: "When enabled, the system administrator is by default the agent account registered by the installer",
		systemAdmin3: "Supports changing the lead administrator, who must exist and be unique",
		createSuccess:
			"The account has been created successfully, FleetView is processing it and expects it to be processed in 30 minutes."
	},
	tips: {
		confirmClose: "Confirm to close lead matching?",
		openTips: "Confirm to enable lead matching for this installer?",
		closetips1: "After closing, the platform can no longer allocate leads to the installer",
		closetips2: "Leads in transit before closing cannot continue to be followed up on the platform",
		closetips3: "After closing, all accounts under the installer will not be able to use the platform normally",
		enterCloseReason: "Please enter the closure reason",
		reasonlength: "Length must not exceed 500 characters",
		usernameExists: "Username already exists",
		emailExists: "Email already exists"
	}
};
