//维护一份权限路由，从后端取到menu-list后，和routerMap比较

import leadsRoutes from "./leads";
import { questionnaireRoutes } from "./questionnaire";
import referrerRoutes from "./referrer";
import systemManageRoutes from "./system-manage";
import trainRoutes from "./train";

//! Setting 图标会导致页面卡顿，谨慎使用
export const routerMap = [
	{
		path: "/home",
		name: "home",
		component: "/home/index",
		meta: {
			icon: "home",
			title: "首页",
			enTitle: "Home",
			isLink: "",
			isHide: false,
			isFull: false,
			isAffix: true,
			isKeepAlive: true
		}
	},

	// 培训中心
	...trainRoutes,

	// 线索平台
	...leadsRoutes,

	// 推荐线索
	...referrerRoutes,

	// 问卷调查
	...questionnaireRoutes,

	// 安装商管理
	{
		path: "/installer-manage",
		name: "user",
		meta: {
			icon: "users",
			title: "安装商管理",
			enTitle: "",
			isLink: "",
			isHide: false,
			isFull: false,
			isAffix: false,
			isKeepAlive: true
		},
		children: [
			{
				path: "/installer-manage/company-list",
				name: "user-company-list",
				component: "/installer-manage/user/company/list",
				meta: {
					icon: "company",
					title: "公司列表",
					enTitle: "",
					isLink: "",
					isHide: false,
					isFull: false,
					isAffix: false,
					isKeepAlive: true
				}
			},
			{
				path: "/installer-manage/company-detail",
				name: "user-company-detail",
				component: "/installer-manage/user/company/detail/index",
				meta: {
					icon: "",
					title: "详情",
					enTitle: "",
					isLink: "",
					isHide: true,
					isFull: false,
					isAffix: false,
					isKeepAlive: false
				}
			},
			{
				path: "/installer-manage/user-list",
				name: "user-user-list",
				component: "/installer-manage/user/user-list/list",
				meta: {
					icon: "personal",
					title: "用户列表",
					enTitle: "",
					isLink: "",
					isHide: false,
					isFull: false,
					isAffix: false,
					isKeepAlive: true
				}
			},
			{
				path: "/installer-manage/qualification-transfer-list",
				name: "qualification-transfer-list",
				component: "/installer-manage/user/qualification-transfer/list",
				meta: {
					icon: "transfer",
					title: "资质转移",
					enTitle: "",
					isLink: "",
					isHide: false,
					isFull: false,
					isAffix: false,
					isKeepAlive: false
				}
			},
			{
				path: "/installer-manage/qualification-transfer-detail",
				name: "qualification-transfer-detail",
				component: "/installer-manage/user/qualification-transfer/detail",
				meta: {
					icon: "",
					title: "创建资质转移",
					enTitle: "",
					isLink: "",
					isHide: true,
					isFull: false,
					isAffix: false,
					isKeepAlive: false
				}
			},
			{
				path: "/installer-manage/feedback/index",
				name: "feedback",
				component: "/installer-manage/feedback/index",
				meta: {
					icon: "feedback",
					title: "安装商反馈",
					enTitle: "",
					isLink: "",
					isHide: false,
					isFull: false,
					isAffix: true,
					isKeepAlive: true
				}
			}
		]
	},

	// 系统管理
	...systemManageRoutes
];
