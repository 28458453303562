export default {
	projectProgress: "项目进度",
	leadsRewards: "线索激励",
	wonRewards: "赢单激励",
	referrerCode: "推荐码",
	name: "姓名",
	referrer: "推荐人",
	referee: "被推荐人",
	referrerPhone: "推荐人电话",
	referrerEmail: "推荐人邮箱",
	refereePhone: "被推荐人电话",
	refereeEmail: "被推荐人邮箱",
	phone: "电话",
	email: "邮箱",
	country: "国家",
	zipCode: "邮编",
	state: "州",
	city: "城市",
	createTime: "创建时间",
	operation: "操作",
	committed: "已提交",
	leadsValid: "线索有效",
	leadsWon: "线索赢单",
	rewardsDistributed: "奖励已发放",
	leadsLost: "线索输单",
	leadsInvalid: "线索无效",
	hasBeenGrant: "已发放",
	partHasBeenGrant: "部分发放",
	hasNotBeenGrant: "未发放",
	approve: "审核",
	batchApprove: "批量审核",
	waitApprove: "待审核",
	hasBeenApproved: "已审核",
	awaitingReview: "待审核",
	reviewed: "已审核",
	accountEmail: "账号/邮箱",
	status: "状态",
	affiliateReward: "关联激励",
	document: "文档",
	none: "无",
	waitSubmitted: "待提交",
	received: "已接收",
	submitted: "已提交",
	referrerSuccess: "确认推荐客户成功?",
	markRequired: "标记为必填项",
	distribute: "发放",
	distributed: "已发放",
	unDistributed: "未发放",
	rewardId: "奖励 ID",
	rewardType: "奖励类型",
	amount: "金额",
	w9: "W-9",
	role: "角色",
	lastExportTime: "最近导出时间",
	leadsReward: "线索奖励",
	leadsWonReward: "线索赢单奖励",
	batchDownloadList: "批量下载名单",
	batchDistribute: "批量发放",
	batchDownload: "批量下载",
	referralInfo: "推荐信息",
	address: "地址",
	message: "留言",
	distributeTime: "发放时间",
	remark: "备注",
	newHomeownerInfo: "新房主信息",
	productInfo: "产品信息",
	account: "账号",
	registerTime: "注册时间",
	registerAddress: "注册地址",
	installer: "安装商",
	installerAccount: "安装商账号",
	aGateSalesCount: "aGate 销售数量",
	serialNumber: "序列号",
	aPowerSalesCount: "aPower 销售数量",
	detail: "详情",
	fromW9: "W-9文档",
	rewardsReceived: "已领取奖励",
	totalNumber: "总数量",
	leadsSubmitted: "提交线索数",
	validLeads: "有效线索",
	address2: "地址2",
	referralData: "推荐数据",
	rewardData: "奖励数据",
	leadsRewardReceived: "收到的线索奖励",
	leadsWonRewardReceived: "收到的赢单奖励",
	submitTime: "提交时间",
	pending: "进行中",
	failed: "失败",
	success: "成功",
	selectOneAtLeast: "至少选择一个",
	limitUploadPdf: "仅支持上传 PDF 文件",
	dollar: "美元",
	confirmApproval: "确认审核通过？",
	rewardDistributeTips: "确认发放奖励完成?",
	newHomeowner: "新房主",
	overLimit: "已超过奖励数量限制"
};
