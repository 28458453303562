export default {
	projectProgress: "Project Progress",
	leadsRewards: "Leads Rewards",
	wonRewards: "Lead Won Rewards",
	referrerCode: "Referrer Code",
	name: "Name",
	referrer: "Referrer",
	referee: "Referee",
	referrerPhone: "Referrer Phone",
	referrerEmail: "Referrer Email",
	refereePhone: "Referee Phone",
	refereeEmail: "Referee Email",
	phone: "Phone",
	email: "Email",
	country: "Country",
	zipCode: "ZipCode",
	state: "State",
	city: "City",
	createTime: "Create Time",
	operation: "Operation",
	committed: "Submited",
	leadsValid: "Leads Valid",
	leadsWon: "Leads Won",
	rewardsDistributed: "Rewards Distributed",
	leadsLost: "Leads Lost",
	leadsInvalid: "Leads Invalid",
	hasBeenGrant: "Distributed",
	partHasBeenGrant: "Partially Distributed",
	hasNotBeenGrant: "Not distributed",
	approve: "Review",
	batchApprove: "Batch Review",
	waitApprove: "Awaiting Review",
	hasBeenApproved: "Reviewed",
	awaitingReview: "Awaiting Review",
	reviewed: "Reviewed",
	accountEmail: "Account/Email",
	status: "Status",
	document: "Document",
	affiliateReward: "Affiliate Reward",
	none: "None",
	waitSubmitted: "Wait Submit",
	received: "Received",
	submitted: "Submitted",
	referrerSuccess: "Confirm referral success?",
	markRequired: "Mark Required",
	distribute: "Distribute",
	distributed: "Distributed",
	unDistributed: "UnDistributed",
	rewardId: "Reward ID",
	rewardType: "Reward Type",
	amount: "Amounts",
	w9: "W-9",
	role: "Role",
	lastExportTime: "Last Export Time",
	leadsReward: "Leads Reward",
	leadsWonReward: "Leads Won Reward",
	batchDownloadList: "Batch Download",
	batchDistribute: "Batch Distribute",
	batchDownload: "Batch Download",
	referralInfo: "Referral Info",
	address: "Address",
	message: "Message",
	distributeTime: "Distribute Time",
	remark: "Remark",
	newHomeownerInfo: "New Homeowner Info",
	productInfo: "Product Info",
	account: "Account",
	registerTime: "Register Time",
	registerAddress: "Register Address",
	installer: "Installer",
	installerAccount: "Installer Account",
	aGateSalesCount: "aGate Sold",
	serialNumber: "Serial Number",
	aPowerSalesCount: "aPower Sold",
	detail: "Detail",
	fromW9: "Form W-9",
	rewardsReceived: "Rewards Received",
	totalNumber: "Total Number",
	leadsSubmitted: "Leads Submitted",
	validLeads: "Valid Leads",
	address2: "Address2",
	referralData: "Referral Data",
	rewardData: "Reward Data",
	leadsRewardReceived: "Leads Reward Received",
	leadsWonRewardReceived: "Leads Won Reward Received",
	submitTime: "Submit Time",
	pending: "In Progress",
	failed: "Failed",
	success: "Success",
	selectOneAtLeast: "Select at least one",
	limitUploadPdf: "Uploading PDF files only",
	dollar: "$",
	confirmApproval: "Confirmation of approval",
	rewardDistributeTips: "Reward distribute has been completed?",
	newHomeowner: "New Homeowner",
	overLimit: "The reward limit has been exceeded"
};
