export default {
	id: "ID",
	enable: "Enable",
	disable: "Disable",
	back: "Back",
	add: "Add",
	warning: "Warning",
	publishAttention: "Publish Attention",
	status: "Status",
	releaseStatus: "Release Status",
	relatedStatus: "Related Status",
	addTime: "Add time",
	sort: "Sort by",
	completeSort: "Complete Sort",
	operate: "Operate",
	edit: "Edit",
	view: "View",
	leads: "Leads",
	delete: "Delete",
	change: "Change",
	batchDel: "Delete selected",
	cancel: "Cancel",
	prevStep: "Previous Step",
	nextStep: "Next Step",
	confirm: "Confirm",
	backModify: "Back to modify",
	close: "Close",
	search: "Search",
	reset: "Reset",
	login: "Login",
	pleaseInput: "Please input",
	pleaseSelect: "Please select",
	release: "Release",
	immediateRelease: "Immediate Release",
	cancelRelease: "Cancel Release",
	releaseAgain: "Release Again",
	unrelease: "Unpublished",
	released: "Published",
	unreleased: "Unpublished",
	operation: "Operation",
	save: "Save",
	submit: "Submit",
	preview: "Preview",
	create: "Create",
	import: "Import",
	detail: "Details",
	all: "All",
	log: "Logs",
	transfer: "Transfer",
	auditPassed: "Review approved",
	auditNotPassed: "Review unapproved",
	refundAndCancel: "Refund and cancellation",
	downloadOrder: "Download order",
	completeDeal: "Processing complete",
	orderShip: "Order delivery",
	dollar: "USD",
	effectiveTime: "Effective Time",
	effectiveImmediately: "Effective immediately",
	timingEffective: "Timing takes effect",
	trainClientSystem: "Train Client system",
	leadsClientSystem: "Leads Client system",

	createTime: "Create Time",
	delTime: "Delete time",
	startTime: "Start time",
	endTime: "End time",
	uploadImgTips: "Upload picture",
	check: "Check",
	acceptFormat: "Supported formats",
	uploadFile: "Upload file",
	yes: "Yes",
	no: "No",
	unfold: "Open",
	fold: "Close",
	placeholderUsername: "Please enter your username.",
	placeholderPassword: "Please enter your password.",
	upload: "Upload",
	maxUploadSize: "Maximum upload file size exceeded 500M",
	collapse: "Collapse",
	expand: "Expand",
	more: "More",
	copy: "Copy",
	tip: "Tips",
	noData: "No data",
	remark: "Remark",
	review: "review",
	copySuccess: "Copy successfully",

	tips: {
		warning: "Tips",
		modify: "Are you sure to change this status?",
		delete: "Are you sure to delete this row?",
		confirm: "Confirm",
		cancel: "Cancel",
		enableItemConfirm: "Are you sure to enable this option?",
		disableItemConfirm: "Are you sure you want to disable this option?",
		markConfirm: "Are you sure to mark this item?",
		cancelMarkConfirm: "Are you sure to cancel the mark?"
	},
	rules: {
		username: "Please enter your username.",
		password: "Please enter your password."
	},
	username: "User name",
	name: "Name",
	password: "Password",
	logoutTip: "Are you sure to log out?",
	logoutSuc: "Logout successfully.",
	fileFormatError: "File format error.",
	fileSuc: "File uploaded successfully.",
	fileFailed: "File upload failed, please re-upload.",
	fileSizeLimitTip: "The size of the uploaded image cannot exceed.",
	fileNumLimitTip: "The number of files exceeds the limit, please remove then upload the files.",
	pageNotFound: "Sorry, the page you visited does not exist.",
	backToHome: "Back to home page.",
	timeoutTip: "Request timed out! Please try again later",

	installer: "Installer",
	homeowner: "Homeowner",
	distributor: "Distributor",
	verified: "Verified",
	unverified: "UnVerified",
	waitForManualVerified: "Waiting for manual verified",
	superAdmin: "Super Administrator",
	primaryAccount: "Primary Account",
	subAccount: "sub-account",
	permanentAccount: "Permanent Account",
	temporaryAccount: "Temporary Account",
	fwhSubAccount: "FWH sub-account",

	colSettings: "Col Settings",
	colName: "Col Name",
	display: "Display",
	sortCol: "Sort",
	sortByCol: "Sort By Col",
	noCols: "No configurable columns yet",
	us: "United States",
	download: "Download",
	matching: "Matching",
	mark: "Mark",
	unMark: "Unmark",
	copyLink: "Copy",

	country: "Country",
	timeLimit: "Time Limit",
	people: "People",
	filterWords: "Filter Words"
};
